import intlTelInpututility from "intl-tel-input/build/js/utils";

$(document).on('turbolinks:load', function () {

    $.getScript("https://www.google.com/recaptcha/api.js");

    if ($(".select-date").length != 0) {
        $(".select-date").flatpickr({
            minDate: 'today',
            dateFormat: "Y-m-d",
        });
    }

    if ($("#new_contact_user").length != 0) {
        initialize_signup_form("new_contact_user");
    }

    if ($("#new_job_user").length != 0) {
        initialize_signup_form("new_job_user");
    }

    if ($("#jobs").length != 0) {
        initialize_signup_form("jobs");
    }

    if ($("#contact_user_phone_no").length != 0) {
        PhoneDisplay(document.querySelector("#contact_user_phone_no"), "phone_no")
    }

    if ($("#job_user_phone").length != 0) {
        PhoneDisplay(document.querySelector("#job_user_phone"), "phone")
    }

    if ($("#job_description").length != 0) {
        CKEDITOR.on("instanceReady", function (event) {
            $("#job_description").css({'display': 'inline-block', 'height': '1px'});
        });
    }

    if ($("#job_requirement").length != 0) {
        CKEDITOR.on("instanceReady", function (event) {
            $("#job_requirement").css({'display': 'inline-block', 'height': '1px'});
        });
    }

    if ($("#job_prefer_experience").length != 0) {
        CKEDITOR.on("instanceReady", function (event) {
            $("#job_prefer_experience").css({'display': 'inline-block', 'height': '1px'});
        });
    }
    init_filepond_single();


});

function PhoneDisplay(input, field) {
// Set Phone Number Country Code Validations
    var errorMsg = document.querySelector(".error-msg")

    if (input) {
        // here, the index maps to the error code returned from getValidationError - see readme
        var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

        // initialise plugin
        var iti = window.intlTelInput(input, {
            initialCountry: "in",
            separateDialCode: true,
            hiddenInput: field,
            utilsScript: intlTelInpututility
        });
        var reset = function () {
            input.classList.remove("error");
            input.closest('.form-group').getElementsByClassName('error-msg')[0].innerHTML = ""
        };


        // on blur: validate
        input.addEventListener('blur', function () {
            reset();
            if (input.value.trim()) {
                if (iti.isValidNumber()) {
                    $('.phone-number').closest("form").find('button[type="submit"]').attr("disabled", false);
                } else {
                    input.classList.add("error");
                    var errorCode = iti.getValidationError();
                    if (typeof errorMap[errorCode] !== "undefined") {
                        input.closest('.form-group').getElementsByClassName('error-msg')[0].innerHTML = errorMap[errorCode];
                        $('.phone-number').closest("form").find('button[type="submit"]').attr("disabled", true);
                    }
                }
            }
        });

        input.addEventListener("countrychange", function (e, countryData) {
        });
        // on keyup / change flag: reset
        input.addEventListener('change', reset);
        input.addEventListener('keyup', reset);
    }
}

export function init_filepond_single() {
    $(".filepond-single").each(function () {
        if ($(this).data("name")) {
            createPoster(
                this,
                $(this).data("name"),
                $(this).data("size"),
                $(this).data("type"),
                $(this).data("url")
            );
        } else {
            FilePond.create(this, {
                name: "filepond"
            });
        }
    });
}

function createPoster(input, name, size, type, url) {
    FilePond.create(input, {
        name: "filepond",
        imagePreviewHeight: 200,
        filePosterMaxHeight: 200,
        files: [{
            options: {
                file: {
                    name: name,
                    size: size,
                    type: type,
                },
                metadata: {
                    poster: url,
                },
            },
        }]
    });
}
