// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
require("../theme/bootstrap.min")
require("../theme/waypoint")

require("../theme/feather")
require("../theme/slick.min")
require("../theme/counterup")
require("jquery-validation")
require("flatpickr");
require("../custom/custom")
require("../custom/kassi")
require("../theme/main")

import feather from "../theme/feather";
window.feather = feather;

import intlTelInput from "intl-tel-input";
window.intlTelInput = intlTelInput;

import counterUp from "../theme/counterup";
window.counterUp = counterUp;

import * as FilePond from "filepond";
window.FilePond = FilePond;

import FilePondPluginFileEncode from "filepond-plugin-file-encode";
window.FilePondPluginFileEncode = FilePondPluginFileEncode;

import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
window.FilePondPluginFileValidateType = FilePondPluginFileValidateType;

import FilePondPluginImagePreview from "filepond-plugin-image-preview";
window.FilePondPluginImagePreview = FilePondPluginImagePreview;

import FilePondPluginFilePoster from "filepond-plugin-file-poster";
window.FilePondPluginFilePoster = FilePondPluginFilePoster;

FilePond.registerPlugin(
    FilePondPluginFileEncode,
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginFilePoster
);




global.$ = jQuery;
global.toastr = require("toastr");

import 'flatpickr';

import  "../stylesheets/application.scss";


import initialize_signup_form from '../custom/kassi'
window.initialize_signup_form = initialize_signup_form;


import {init_filepond_single} from '../custom/custom';
window.init_filepond_single = init_filepond_single;

