function initialize_signup_form(ele_id) {

    var form_id = "#" + ele_id;
    $(form_id).validate({
        ignore: ":hidden",
        rules: {
            "contact_user[name]": {required: true},
            "contact_user[email]": {required: true, email: true},
            "contact_user[message]": {required: true},
            "contact_user[phone_no]": {number: true},
            "job_user[full_name]": {required: true},
            "job_user[email]": {required: true},
            "job_user[phone]": {required: true, number: true},
            "job_user[desired_salary]": {number: true},
            "job[description]": {
                required: function () {
                    CKEDITOR.instances.job_description.updateElement();
                },
                minlength: 10
            },
            "job[requirement]": {
                required: function () {
                    CKEDITOR.instances.job_requirement.updateElement();
                },
                minlength: 10
            },
            "job[prefer_experience]": {
                required: function () {
                    CKEDITOR.instances.job_prefer_experience.updateElement();
                },
                minlength: 10
            }
        },
        errorPlacement: function (error, element) {
            if (element.hasClass('phone-number')) {
                element.closest(".iti").parent().append(error);
            }
            else {
                element.closest("div").append(error);
            }
        },
        onkeyup: false, //Only do validations when form focus changes to avoid exessive ASI calls
        submitHandler: function (form) {
            disable_and_submit(form_id, form, "false", locale);
        }
    });
}

function disable_and_submit(form_id, form, ajax) {
    disable_submit_button(form_id, locale);
    form.submit();
}

function disable_submit_button(form_id) {
    $(form_id).find("button").attr('disabled', 'disabled');
    $(form_id).find("button").text("please_wait");
}

export default initialize_signup_form;
